import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-t5-h4UQHm1cBe7XJwITJQSIT5WVFbSY",
  authDomain: "medic-c2645.firebaseapp.com",
  projectId: "medic-c2645",
  storageBucket: "medic-c2645.appspot.com",
  messagingSenderId: "772880101417",
  appId: "1:772880101417:web:d1b485ef8b53d5ad1fec38",
  measurementId: "G-VTKBFYGPJ4"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firedb = firebase.firestore();