import React, {useState, useEffect} from "react";
import { auth, firedb } from "../../firebase";
import ReactHtmlParser from 'react-html-parser';

const Home = (props) => {

    const [hpData,setHpData]=useState([])
    const fetchHp=async()=>{
        const response=firedb.collection('homePage');
        const data=await response.get();
        data.docs.forEach(item=>{
         setHpData([...hpData,item.data()])
        })
      }
    
      useEffect(() => {
        fetchHp();
      }, [])
    
  return (
    <div>
      <div className="text-center p-5">
        <img src={process.env.PUBLIC_URL +"/img/main-logo.png"} alt="site-logo" width="220px" />
        <p className="my-3">{props.user.email}</p>
        <button className="btn btn-danger" onClick = {() => {auth.signOut()}}>Logout</button>  
      </div>
    <section className="video-section bg1 py-4">
        <div className="container-fluid">
            <div className="mb-3">
                <h1 className="text-white">Welcome To Your Virtual Work Experience</h1>
            </div>
            <div className="row">
            <div className="col-md-9">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" title="youtube video" width="1250" height="703" src={hpData[0]!==undefined ? hpData[0].videoCode : ''} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <div className="col-md-3">
                <div className="">
                    <iframe src={ hpData[0]!==undefined ? hpData[0].slidoCode : '' } title="sildo" height="100%" width="100%" frameBorder="0" style={{minHeight: 560+'px'}}></iframe>
                </div>
            </div>
            </div>
            <div className="col-md-12 py-3 text-center">
                <a href={ hpData[0]!==undefined ? hpData[0].videoCode2 : '' } target="blank" className="text-white h5">Trouble viewing the stream? Please click here</a>
            </div>
        </div>
    </section>
    <section className="wex-resources py-5 bg2">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].attendanceTitle!==undefined) ? hpData[0].attendanceTitle : '' }</h3>
                    <p className="mt-3">{(hpData[0]!==undefined && hpData[0].attendanceDesc!==undefined) ? hpData[0].attendanceDesc : '' }</p>
                </div>
                <div className="col-md-8">
                    <div className="img-wrapper">
                        <img src={(hpData[0]!==undefined && hpData[0].attendanceImg!==undefined) ? hpData[0].attendanceImg : '' } alt="" className="img-fluid" />
                    </div>
                    <div className="resource-title text-center p-5 bg3">
                        <h4><span className="text-white">{(hpData[0]!==undefined && hpData[0].attendanceTitle!==undefined) ? hpData[0].attendanceTitle : '' }</span></h4>
                    </div>
                    <a href={(hpData[0]!==undefined && hpData[0].attendanceLink!==undefined) ? hpData[0].attendanceLink : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{(hpData[0]!==undefined && hpData[0].attendanceLinkText!==undefined) ? hpData[0].attendanceLinkText : '' }</a>
                </div>
            </div>
        </div>
    </section>
    <section className="wex-resources-section py-5">
        <div className="container">
        <div className="row"> 
            <div className="col-md-12">
            <h3 className="text-center mb-4 heading-title">{ hpData[0]!==undefined ? hpData[0].wexHeading : '' }</h3>
            <p className="text-center lead mb-5">{ hpData[0]!==undefined ? hpData[0].wexDesc : '' }</p>
            </div>
            <div className="col-md-6 mt-4">
            <div className="img-wrapper">
                <img src={ hpData[0]!==undefined ? hpData[0].wexImg1 : '' } alt="" className="img-fluid" />
            </div>
            <div className="resource-title text-center p-5 bg3">
                <h4><span className="text-white">{ hpData[0]!==undefined ? hpData[0].wexTitle1 : '' }</span></h4>
            </div>
            <a href={ hpData[0]!==undefined ? hpData[0].wexBtnLink1 : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{ hpData[0]!==undefined ? hpData[0].wexBtn1 : '' }</a>
            </div>
            <div className="col-md-6 mt-4">
            <div className="img-wrapper">
                <img src={ hpData[0]!==undefined ? hpData[0].wexImg2 : '' } alt="" className="img-fluid" />
            </div>
            <div className="resource-title text-center p-5 bg3">
                <h4><span className="text-white">{ hpData[0]!==undefined ? hpData[0].wexTitle2 : '' }</span></h4>
            </div>
            <a href={ hpData[0]!==undefined ? hpData[0].wexBtnLink2 : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{ hpData[0]!==undefined ? hpData[0].wexBtn2 : '' }</a>
            </div>
        </div>
        </div>
    </section>
    <section className="section-logos">
        <div className="container">
            <h4 class="lead mb-5 heading-title pb-5">
            Check Out Our Other Virtual Work Experience Programmes

            </h4>
        <div className="row">
            <a href={ hpData[0]!==undefined ? hpData[0].opLink1 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg1 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink2 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg2 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink3 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg3 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink4 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg4 : '' } alt="" className="img-fluid" />
            </a> 
        </div>
        
        </div>
    </section>
    {(hpData[0]!==undefined && hpData[0].hpHTML!==undefined) ? ReactHtmlParser(hpData[0].hpHTML) : '' }
    <section className="post-event-action py-5">
        <div className="container">
        <div className="row"> 
            <div className="col-md-10 mx-auto">
            <h3 className="text-center mb-4 heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle!==undefined) ? hpData[0].eaTitle : '' }</h3>
            <p className="mt-5 mb-5 text-center">
            {(hpData[0]!==undefined && hpData[0].eaDesc!==undefined) ? hpData[0].eaDesc : '' }
            </p>
            <div className="row">
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle1!==undefined) ? hpData[0].eaTitle1 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].eaDesc1!==undefined) ? hpData[0].eaDesc1 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].eaLink1!==undefined) ? hpData[0].eaLink1 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].eaLinkTitle1!==undefined) ? hpData[0].eaLinkTitle1 : '' }</a>
             </div>
            </div>
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle2!==undefined) ? hpData[0].eaTitle2 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].eaDesc2!==undefined) ? hpData[0].eaDesc2 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].eaLink2!==undefined) ? hpData[0].eaLink2 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].eaLinkTitle2!==undefined) ? hpData[0].eaLinkTitle2 : '' }</a>
             </div>
            </div>
            </div>
            </div>
          
        </div>
        </div>
    </section>
    {(hpData[0]!==undefined && hpData[0].hpCTA!==undefined) ? ReactHtmlParser(hpData[0].hpCTA) : '' }

   {/* <section className="wex-announcements bg2 py-5">
        <div className="container">
        <div className="row">
            <div className="col-md-4">
            <div className="w-a-left">
                <h3 className="mb-5 heading-title">WEX Announcements</h3>
                <p className="d-flex"><i className="mr-3 mt-1 fas fa-info-circle text1"></i>This patient pathway is entirely fictional but has been produced by a team of clinicians to ensure clinical accuracy</p>
                <p className="d-flex"><i className="mr-3 mt-1 fas fa-info-circle text1"></i>The medical team will be wearing PPE at all times and will be social distancing wherever possible.</p>
                <p className="d-flex"><i className="mr-3 mt-1 fas fa-info-circle text1"></i>If anything today causes you distress, please inform Medic Mentor and we would be happy to support you please contact us on admin@medicmentor.org</p>
            </div>
            </div>
            <div className="col-md-8">
            <div className="w-a-right">
                <div className="qa-block p-4 bg3 text-white mb-5 d-flex">
                <i className="mr-3 mt-2 fab fa-instagram text1"></i>
                <div>
                    <h3>Have You Followed Us On Instagram?</h3>
                    <p>Have you shared a picture of your work experience workstation? We LOVE to do this every session, let’s see your work experience day set up and tag us in! We also will be hosting LIVE IGTV’s in the breaks, so you get to squeeze more time out of the specialities you’ve seen! Please note we will not post pictures tagged where students identity can be revealed
                        {/* <a href="https://www.instagram.com/alliedhealthcarementor/" target="blank" className="d-block mt-3 text-white font-weight-bold">
CHECK OUT OUR INSTAGRAM <i className="fas fa-arrow-right d-inline ml-2"></i></a> */}
                {/*    </p>
                </div>
                </div>
                 <div className="qa-block p-4 bg3 text-white mb-5 d-flex">
                <i className="mr-3 mt-2 fas fa-star text1"></i>
                <div>
                    <h3>‘Get Into…’ Conferences</h3>
                    <p>Please keep a close eye on both the website and your Allied Health emails for our fantastic ‘Get into…’ Conferences. We will be dropping live dates very soon. Please remember this work experience forms only part of the information you require in order to make an informed choice. Attending a conference will significantly improve your understanding of the requirements to make for a successful application. So don’t miss out!</p>
                </div>
                </div>
                <div className="qa-block p-4 bg3 text-white mb-5 d-flex">
                <i className="mr-3 mt-2 fas fa-star text1"></i>
                <div>
                    <h3>Sildo</h3>
                    <p>Remember, following each ‘Spotlight into the profession’, please ask questions using the 'Slido' box below. This is being moderated and will only be open after the clinical session has ended!</p>
                </div>
                </div> */}
               {/* <div className="qa-block p-4 bg3 text-white mb-5 d-flex">
                <i className="mr-3 mt-2 fas fa-star text1"></i>   
                <div>
                    <h3>Please Remember</h3>
                    <p>Finally, please remember, your conduct will be monitored, students who are posting inappropriate conduct, spamming the Q and A platform or generally acting in an unprofessional manner will be removed from the programme entirely. We have a zero tolerance for unprofessional behaviour.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>*/}
    {/* <section className="post-event-action py-5">
        <div className="container">
        <div className="row"> 
            <div className="col-md-10 mx-auto">
            <h3 className="text-center mb-4 heading-title">Post Event Actions</h3>
            <p className="mt-5 mb-5 text-center">
            Please ensure you register your attendance by following the link below before midnight on the 10th April 2021. Failure to do so will mean you cannot receive your certificate and proof of attendance for this event.
            </p>
            <div className="row">
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">Register Your Attendance</h3>
            <p className="my-5">
            Please complete the attendance form in the link below to recieve your certificate
            </p>
            <a href="https://airtable.com/shrN8myoAg5i9NDXa" target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">Register Attendance</a>
             </div>
            </div>
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">Post Event Survey</h3>
            <p className="my-5">
            Please complete the post event survey to help us continually improve the work experience programme
            </p>
            <a href=" https://forms.gle/t5MV5HLbd5hUucV79" target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">Complete Survey</a>
             </div>
            </div>
            </div>
            </div>
          
        </div>
        </div>
    </section> */}
    {/*<section className="section-cta text-white">
        <div className="container">
        <div className="row">
            <div className="col-md-6 mx-auto text-center">
            <h2><span className="heading-title d-block text-white">Start Your Journey With</span><span className="d-block heading2">Your Medic Family</span></h2>
            </div>
        </div>
        </div>
    </section>
    
    <footer className="py-2 bg3 text-white text-center">
        <div className="container">
        Copyright © 2021 Your Medic Family. All Rights Reserved.
        </div>
    </footer>*/}
    <footer className="py-2 bg1 text-white text-center">
        <div className="container">
        {(hpData[0]!==undefined && hpData[0].footer!==undefined) ? hpData[0].footer : '' }
        </div>
    </footer>
    </div>
  );
};
export default Home;